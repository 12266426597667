import React from 'react';
import Loading from './Loading'
import Typography from '@material-ui/core/Typography';
import {GetApiPath} from '../Config'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import {trimHtml} from '../helper';

const useStyles = theme => ({
    imgCover: {
        maxWidth: '100%',
    },
    episodes: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        display: "flex",
        flexWrap: "wrap",
        //justifyContent: "space-between",
    },
    episodesButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    playTip: {
        marginTop: theme.spacing(5),
    },
    mainInfo: {
        '& p': {
            lineHeight: 2,
        }
    },
    infoTitle: {
        fontWeight: 'bold'
    }
});

class ItemDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            id: props.id,
            fetchted: false,
            content: {},
            playList: [],
        }
        //console.log(props);
    }

    componentDidMount() {
        if (this.state.content.length && this.state.content.length > 0) {
            return;
        }
        this.fetchFn()
    }

    fetchFn = () => {
        if (this.props.id == null || this.props.id === 0) {
            return;
        }
        fetch(GetApiPath("view") + "/" + this.props.id)
            .then(function(resp) {
                if (!resp.ok) {
                    throw new Error('Response was not ok.');
                }
                return resp.json();
            })
            .then((respJson) => {
                //DebugPrint(respJson);
                this.setState({
                    id: this.props.id,
                    content: respJson,
                    fetchted: true
                });
                this.parsePlaySource(respJson.vod_play_url);
            })
            .catch((error) => {
                this.setState({
                    error: true
                });
                console.log('There has been a problem with your fetch operation: ', error.message);
            });
    }

    parsePlaySource(playUrl) {
        let souceArr = playUrl.split("$$$");
        let playList = [];
        for (let k in souceArr) {
            playList[k] = [];
            souceArr[k].split("#").map((item) => {
                let itemArr = item.split("$");
                playList[k].push({
                    title: itemArr[0],
                    url: itemArr[1],
                })
                return 1;
            });
        }
        this.setState({playList: playList})
        //console.log(this.state.playList);
    }

    play = (id, title, session, url) => {
        this.props.handlerPlay(id, title, session, url);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(prevProps.id)
        //console.log(prevState.id)
        //console.log(this.props.id)
        if (prevProps.id !== this.props.id) {
            this.setState({fetchted: false})
            this.fetchFn()
        }
    }

    render() {
        const { classes } = this.props;

        if (this.state.error) {
            return (
                <Typography align="center">加载失败</Typography>
            );
        }
        if (this.state.fetchted === false) {
            return (
                <Loading />
            );
        }
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <img src={this.state.content.vod_pic} alt="img" className={classes.imgCover} />
                    </Grid>
                    <Grid item xs={8}>
                        <div className={classes.mainInfo}>
                            <Typography><span className={classes.infoTitle}>名称：</span>{this.state.content.vod_name}</Typography>
                            <Typography><span className={classes.infoTitle}>别名：</span>{this.state.content.vod_sub}</Typography>
                            <Typography><span className={classes.infoTitle}>地区：</span>{this.state.content.vod_area}</Typography>
                            <Typography><span className={classes.infoTitle}>年份：</span>{this.state.content.vod_year}</Typography>
                            <Typography><span className={classes.infoTitle}>类型：</span>{this.state.content.type_name}</Typography>
                            <Typography><span className={classes.infoTitle}>语言：</span>{this.state.content.vod_lang}</Typography>
                            <Typography>
                                <span className={classes.infoTitle}>豆瓣评分：</span>
                                {
                                    this.state.content.vod_douban_id > 0 ?
                                        <Link href={"https://movie.douban.com/subject/" + this.state.content.vod_douban_id} target="_blank">{this.state.content.vod_douban_score}</Link> :
                                        this.state.content.vod_douban_score
                                }
                            </Typography>
                            <Typography><span className={classes.infoTitle}>发行日期：</span>{this.state.content.vod_pubdate}</Typography>
                            <Typography><span className={classes.infoTitle}>主演：</span>{this.state.content.vod_actor}</Typography>
                            <Typography component="div">
                                <span className={classes.infoTitle}>剧情介绍：</span>
                                <Typography>{trimHtml(this.state.content.vod_content)}</Typography>
                            </Typography>
                        </div>
                        <Alert className={classes.playTip} severity="info" elevation={6} variant="filled">请优先使用M3U8播放源。</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            this.state.playList.length >= 2 &&
                            <React.Fragment>
                                <Typography>M3U8源：</Typography>
                                <Paper className={classes.episodes}>
                                    {
                                        this.state.playList[1].map((item, key) => {
                                            return <Button
                                                className={classes.episodesButton}
                                                key={key}
                                                onClick={() => {
                                                    this.play(this.state.content.vod_id, this.state.content.vod_name, item.title, item.url);
                                                }}
                                                color="primary"
                                            >{item.title}</Button>
                                        })
                                    }
                                </Paper>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            this.state.playList.length >= 1 &&
                            <React.Fragment>
                                <Typography>在线云播：</Typography>
                                <Paper className={classes.episodes}>
                                    {
                                        this.state.playList[0].map((item, key) => {
                                            return <Button
                                                className={classes.episodesButton}
                                                key={key}
                                                onClick={() => {
                                                    this.play(this.state.content.vod_id, this.state.content.vod_name, item.title, item.url);
                                                }}
                                                color="primary"
                                            >{item.title}</Button>
                                        })
                                    }
                                </Paper>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(ItemDetail);