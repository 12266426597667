const Config = {
    mode: "release", //debug：打印日志在控制台 release
    apiHost : "https://mapi.ibeetv.com",
    //apiHost : "http://127.0.0.1",
    apiPath: {
        search: "/movie/search",
        view: "/movie/view"
    }
};

function GetApiPath(type) {
    return Config.apiHost + (Config.apiPath.hasOwnProperty(type) ? Config.apiPath[''+type+''] : '');
}

function DebugPrint(obj) {
    if (Config.mode !== 'debug') {
        return;
    }
    console.log(obj);
}

export {Config, GetApiPath, DebugPrint};