import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchList from './SearchList';
import VideoPlayer from './Player';
import ItemDetail from './ItemDetail';
import Grid from "@material-ui/core/Grid";
import SearchBar from "./SearchBar";

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabPanel: {
        padding: theme.spacing(2)
    }
});

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            playUrl: '',
            id: 0,
            vodId: 0,
            vodTitle: '',
            vodSession: '',
            kwd: '',
        }

        if (this.props.match.params.kwd !== undefined) {
            this.state.value = 0;
            this.state.kwd = this.props.match.params.kwd
        }
        if (this.props.match.params.id !== undefined) {
            this.state.value = 1;
            this.state.id = this.props.match.params.id
        }
        if (this.props.match.params.url !== undefined) {
            this.state.value = 2;
            this.state.playUrl = this.props.match.params.url
        }
    }

    handlerPlay = (id, title, session, playUrl)  => {
        this.setState({value: 2, playUrl: playUrl, vodId: id, vodTitle: title, vodSession: session})
    }

    handlerDetail = (id) => {
        this.setState({value: 1, id: id})
    }

    render() {
        //console.log(this.state);
        //console.log(this.props);
        const { classes } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };
        const handlerSearch = (kwd) => {
            this.setState({kwd: kwd});
            //console.log(kwd);
        }

        return (
            <div className={classes.root}>
                <Paper square>
                    <Tabs
                        value={this.state.value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                    >
                        <Tab label="搜索" {...a11yProps(0)} />
                        <Tab label="详情"  {...a11yProps(1)} />
                        <Tab label="播放"  {...a11yProps(2)} />
                    </Tabs>
                </Paper>
                <div className={classes.tabPanel} hidden={this.state.value !== 0}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}><SearchBar handlerSearch={handlerSearch} /></Grid>
                        <Grid item xs={12}>
                            <SearchList kwd={this.state.kwd} onClickDetail={this.handlerDetail} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tabPanel} hidden={this.state.value !== 1}>
                    {this.state.id > 0 && <ItemDetail id={this.state.id} handlerPlay={this.handlerPlay} />}
                </div>
                <div className={classes.tabPanel} hidden={this.state.value !== 2} style={{padding: '0.8rem 0'}}>
                    {this.state.playUrl !== '' &&
                    <VideoPlayer playUrl={this.state.playUrl} id={this.state.vodId} title={this.state.vodTitle} session={this.state.vodSession} />}
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Main);