import SearchBar from './SearchBar'
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        //marginTop: theme.spacing(15),
    }
}));

export default function Home(props) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <SearchBar />
        </Grid>
    );
}