import React from 'react';
import {ListItemIcon } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import '../css/search/item.css'

class SearchListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.item.id
        }
    }

    handleButtonClick = () => {
        this.props.onClickDetail(this.state.id);
    }

    render() {
        return (
            <React.Fragment>
            <div key={this.props.item.id} className="item-container">
                <div className="item-summary">
                    <div className="title-container">
                        <ListItemIcon className="title-icon"><GradeIcon style={{ color: "rgb(169, 169, 169)" }} /></ListItemIcon>
                        <div className="title-text">
                            <Typography component="div" variant="h6" color="textPrimary">{this.props.item.title}</Typography>
                            <Typography component="div" style={{marginRight: "1.5rem"}} variant="subtitle2" color="textSecondary" display="inline">
                                类型：{this.props.item.typeName}
                            </Typography>
                            {/*<Typography component="div" variant="subtitle2" color="textSecondary" display="inline">
                                更新时间：{this.props.item.vod_update_time.split(' ')[0]}
                            </Typography>*/}
                        </div>
                    </div>
                    <Button color="primary" onClick={this.handleButtonClick}>详情</Button>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default SearchListItem;