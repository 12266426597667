import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    loading: {
        position: 'relative',
        right: theme.spacing(3)
    }
}));

function Loading(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress className={classes.loading} />
        </div>
    );
}

export default Loading;