import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Header from './pages/Header';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Main from './pages/Main';

const theme = createMuiTheme({
  /*palette: {
    primary: {
      main: '#5593d1'
    },
  },*/
});

const useStyles = makeStyles(theme => ({
  contentBody: {
    marginTop: theme.spacing(2),
    marginBottom: '3rem'
  },
}));

function App() {
  const classes = useStyles();
  const year = new Date();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Header/>
        <Container className={classes.contentBody} maxWidth={"lg"}>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path={["/search/:kwd", "/view/:id", "/play/:url"]} component={Main}/>
            <Route path="*">
              <NotFound/>
            </Route>
          </Switch>
        </Container>
        <footer>
          <Container maxWidth={"lg"}>
            <Typography>&copy; {year.getFullYear()} iBeeTv.com 粤ICP备11041704号</Typography>
          </Container>
        </footer>
      </ThemeProvider>
    </Router>
  );
}

export default App;
