import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../logo.png';
import Link from '@material-ui/core/Link';

const useStyles = {
    textColor: {
        color: 'white'
    },
    logoLink: {
        height: "40px",
        marginRight: "15px"
    },
    logo: {
        maxHeight: "100%"
    }
};

class Header extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <AppBar position="static">
                <Grid container>
                    <Grid item md={8}>
                        <Toolbar>
                            <Link component={RouterLink} to="/" className={classes.logoLink}>
                                <img className={classes.logo} src={logo} alt="logo"/>
                            </Link>
                            <Typography variant="h6" color="inherit" noWrap>
                                小黄蜂
                            </Typography>
                            <Button aria-controls="simple-menu" aria-haspopup="true"
                                    className={classes.textColor}
                                    component={RouterLink} to="/">
                                首页
                            </Button>
                        </Toolbar>
                    </Grid>
                </Grid>
            </AppBar>
        );
    }
}

export default withStyles(useStyles)(Header);
//export default Header;