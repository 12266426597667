import React from 'react';
import Loading from './Loading'
import { GetApiPath } from '../Config'
import SearchListItem from './SearchListItem'
import Typography from '@material-ui/core/Typography';

class SearchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            error: false,
            kwd: props.kwd,
        }

    }

    fetchFn = () => {
        if (this.props.kwd === '') {
            return;
        }
        fetch(GetApiPath('search') + '?kwd=' + this.props.kwd)
            .then(function (resp) {
                if (!resp.ok) {
                    throw new Error('Network response was not ok.');
                }
                return resp.json();
            }).then((respJson) => {
                //console.log(respJson);
                /*if (respJson.map === undefined) {
                    throw new Error('error content');
                }*/
                this.setState({
                    error: false,
                    list: respJson
                })
            }).catch((error) => {
                this.setState({
                    error: true
                });
                console.log('There has been a problem with your fetch operation: ', error.message);
            });
    }

    componentDidMount() {
        this.fetchFn()
    }

    //在更新后会被立即调用。首次渲染不会执行此方法。
    //https://zh-hans.reactjs.org/docs/react-component.html#componentdidupdate
    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(prevProps.kwd)
        //console.log(prevState.kwd)
        //console.log(this.props.kwd)
        if (prevProps.kwd !== this.props.kwd) {
            this.setState({kwd: this.props.kwd, list: []})
            this.fetchFn()
        }
    }


    render() {
        //console.log(this.state);
        if (this.props.kwd == null || this.props.kwd.length === 0) {
            return (
                <Typography>没有输入搜索词</Typography>
            );
        }
        if (this.state.list.info !== undefined) {
            return (
                <Typography>{this.state.list.info}</Typography>
            );
        }
        if (this.state.error) {
            return (
                <Typography>服务不可用</Typography>
            );
        }
        if (this.state.list.length === 0) {
            return (
                <Loading />
            );
        }
        return (
            <React.Fragment>
                {
                    this.state.list.map((item) => {
                        return <SearchListItem key={item.id} item={item} onClickDetail={this.props.onClickDetail} />
                    })
                }
            </React.Fragment>
        );
    }
}

export default SearchList;