import React, { useState } from 'react';
import {Redirect} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: theme.spacing(50),
    },
    input: {
        marginLeft: theme.spacing(1),
        minWidth: theme.spacing(43),
        '& input': {
            height: '1.5em',
        }
    }
}));

function SearchBar(props) {
    const classes = useStyles();
    const [go, setGo] = useState(0);
    const [kwd, setKwd] = useState("")
    //点击搜索，跳转倒搜索页面
    const goFn = (e) => {
        e.preventDefault();
        const kwd = e.target[0].value;
        if (props.handlerSearch !== undefined) {
            props.handlerSearch(kwd);
            return;
        }
        setGo(1)
        setKwd(kwd)
    }
    //props.handlerSearch(kwd);
    //console.log(props.handlerSearch);

    if (go === 1 && props.handlerSearch === undefined) {
        return (
            <Redirect to={{pathname: "/search/" + kwd}} />
        );
    }

    return (
        <Grid container item md={12} alignContent="center" justifyContent="center">
            <Paper component="form" className={classes.root} onSubmit={goFn}>
                <InputBase name="kwd" placeholder="搜索" className={classes.input} required />
                <IconButton type="submit"  aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
        </Grid>
    );
}

export default withRouter(SearchBar);